import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import FirstEntryPoint from './FirstEntryPoint';
import SecondEntryPoint from './SecondEntryPoint';
import { Helmet } from 'react-helmet';

const App = () => {
  const helmetContext = {};
  return (
    
    <Router>
      <Routes>
        <Route path="/:parameter" element={<FirstEntryPoint />}  />
        <Route path="/web/:parameter" element={<SecondEntryPoint />} />
      </Routes>
    </Router>
   
  );
};

export default App;
