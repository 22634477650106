import React from 'react';
import { Helmet } from 'react-helmet';

const OGPMetaTags = ({ title, description, imageUrl }) => {
  return (
    <Helmet>
      <link rel="apple-touch-icon" href={imageUrl} />
      <link rel="manifest" href={imageUrl} />
      <link rel="icon" href={imageUrl} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={imageUrl} />
    </Helmet>
  );
};

export default OGPMetaTags;
