import { useRef, useState, useEffect } from 'react';
import './App.css';
import {MdLocationPin} from 'react-icons/md';
import OGPMetaTags from './components/OGPMetaTags';



const SecondEntryPoint = () => {



  const videoRef = useRef(null);
  const [playing, setPlaying] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [videoResult, setVideoResult] = useState({})
  const [muted, setMuted] = useState(true);
  const [imageProfile, setImageProfile] = useState('');
  const queryParameters = new URLSearchParams(window.location.search);
  const id = queryParameters.get("id");
  const idVideo = window.location.pathname;





  const onVideoPress = () => {
    if (playing) {
      videoRef.current.pause();
      setPlaying(false);
      
    } else {
      videoRef.current.play();
      setPlaying(true);
    }
    
  };

 
  


  const apiEndpoint = `https://app.vacatchy.com/api/v1/share_video_info/${idVideo.substring(5, idVideo.length)}`;


  const getVideoInformation = async () => {

    try {
      setLoading(true);
      const res = await fetch(apiEndpoint);

      const data = await res.json();
      

      if (typeof data === 'undefined') {
        
        setError(true);
      } else if (data.length === 0 || data.detail === 'No video found'|| !res.ok ) {
        setError(true);
      } else {
       
        setVideoResult(data);
        setMuted(!data.audio_rights);
        setError(false);
        setImageProfile(`${data.user_image}?timestamp=${Date.now()}`);
        
        
      }
      setLoading(false);
    } catch (error) {
      clearTimeout(id);
      setError(true);
      setLoading(false);
     

    }
  };


  useEffect(() => {
    getVideoInformation();
  }, []);






  return (
    <div className="app">

    

      {(!loading && !error) ? (<div className="app_videos">



            <div className="video">
		<img className='logo' src="https://storage.vacatchy.com/app-assets/logo_web.png" alt="logo" />
                <a href="https://apps.apple.com/us/app/vacatchy/id6498716203" target="_blank" rel="noreferrer">
                  <button className='buttonDownload' >Download Vacatchy App</button>
                 </a>
   
                      
            
                <video
                className="video_player"  

                loop
                ref={videoRef}
                onClick={onVideoPress}
                muted={muted}
             
                playsInline
                autoPlay={true}
                preload='auto'
                type='video/mp4'
                > 
                <source src={videoResult.video_url} type='video/mp4'></source>
                </video>
              
              
              
                <div className="videoSidebar">
                    


             

                 </div>

                
                 <div className="videoFooter">
                    <div className="videoFooter__user">
                      {(!imageProfile) ? <div
                      className="videoFooter__profile">

                      </div> :
                      <img 
                          className="videoFooter__image"
                          src={imageProfile}
                          alt=""
                      ></img>}
                      <div  >
                     
                        
                          <h3 className="videoFooter_text">{videoResult.user_username}</h3>
                        
                      </div>
                    </div>
                  
                    
                 </div>
                 <div className='location_container'>
               
               

                    {(videoResult.related_poi_video_name) ? (<p className="location" >  <MdLocationPin size={15}/>{videoResult.related_poi_video_name}</p>) :
                    (<p className="location" >  <MdLocationPin size={15}/>{videoResult.location}</p>)}
                  </div>
                 <div className='description'>
                    <p className="videoFooter_description" >{videoResult.video_description}</p>
                  </div>
            </div>

          
        </div>) : null}

        {error ? <div>
          <p>Error</p>
          </div>: null}


         

        
     



    </div>
  );
}

export default SecondEntryPoint;

